import React, { useState, useEffect } from "react";
import "../style/OrdersPage.css"; // Add styles for trade history
import { getuserorder, cancelorder } from "../api"; // Adjust the path according to your file structure

const OrdersPage = () => {
  const [orders, setOrders] = useState([]);
  const [showOpenOrders, setShowOpenOrders] = useState(true); // State to toggle between Open and Closed orders

  // Fetch orders when the component mounts
  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await getuserorder(); // Make sure this API returns the orders
        setOrders(response.data); // Assuming the response contains the orders in the 'data' field
      } catch (error) {
        console.error("Error fetching orders:", error);
      }
    };

    fetchOrders();
  }, []);

  const handleCancel = async (orderId, orderType, index) => {
    try {
      await cancelorder(orderId, orderType); // Assuming cancelorder takes orderId and orderType as arguments
      setOrders((prevOrders) =>
        prevOrders.map((order, i) =>
          i === index ? { ...order, order_status: "Cancelled" } : order
        )
      );
    } catch (error) {
      console.error("Error cancelling order:", error);
    }
  };

  // Separate open and closed orders
  const openOrders = orders.filter((order) => order.order_status === 0);
  const closedOrders = orders.filter(
    (order) => order.order_status === 1 || order.order_status === 2
  );
  
  return (
    <div className="OrdersPage-history">
      {/* Toggle buttons for Open and Closed Orders */}
      <div class="line-break"></div>

      <div>
        <button
          className={`toggle-btn open ${showOpenOrders ? 'active' : ''}`}
          onClick={() => setShowOpenOrders(true)}
        >
          Open Orders
        </button>
        <button
          className={`toggle-btn closed ${!showOpenOrders ? 'active' : ''}`}
          onClick={() => setShowOpenOrders(false)}
        >
          Closed Orders
        </button>
      </div>
      <div class="line-break"></div>

      <div className="OrdersPage-container">
        {/* Display Open Orders */}
        {showOpenOrders && (
          <div className="order-section">
            <table className="table">
              <thead>
                <tr>
                  <td >Pair</td>
                  <td>Price</td>
                  <td >Volume</td>
                  <td>Remaining</td>
                  <td>Status</td>
                  <td>Time</td>
                  <td >Order Type</td>
                  <td>Action</td>
                </tr>
              </thead>
              <tbody className="table-group-divider">
                {openOrders.map((order, index) => (
                  <tr key={order.orderId}>
                                    <td >{index}</td>
                    <td>{order.symbol}</td>
                    <td>{order.price}</td>
                    <td>{order.volume + order.total_executed}</td>
                    <td>{order.volume}</td>
                    <td>
                      {order.order_status === 0 ? "Open" : order.order_status}
                    </td>
                    <td>{new Date(order.createdAt).toLocaleString()}</td>
                    <td>
                      <span
                        style={{
                          color: order.orderType === "buy" ? "green" : "red",
                        }}
                      >
                        {order.orderType === "buy" ? "Buy" : "Sell"}
                      </span>
                    </td>
                    <td>
                      {order.order_status === 0 && (
                        <button
                          className="cancel-btn"
                          onClick={() =>
                            handleCancel(order.orderId, order.orderType, index)
                          }
                        >
                          Cancel
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}

        {/* Display Closed Orders */}
        {!showOpenOrders && (
          <div className="order-section">
            <table className="table closed-orders-table">
              <thead>
                <tr>
                <td >No.</td>
                  <td>Pair</td>
                  <td>Price</td>
                  <td>Volume</td>
                  <td>Status</td>
                  <td>Time</td>
                  <td>Order Type</td>
                  <td>Executed</td>
                </tr>
              </thead>
              <tbody className="table-group-divider">
                {closedOrders.map((order, index) => (
                  <tr key={order.orderId}>
                  <td >{index}</td>
                    <td>{order.symbol}</td>
                    <td>{order.price}</td>
                    <td>{order.volume}</td>
                    <td>
                      {order.order_status === 1
                        ? "Closed"
                        : order.order_status === 2
                        ? "Cancelled"
                        : order.order_status}
                    </td>
                    <td>{new Date(order.createdAt).toLocaleString()}</td>
                    <td>
                      <span
                        style={{
                          color: order.orderType === "buy" ? "green" : "red",
                        }}
                      >
                        {order.orderType === "buy" ? "Buy" : "Sell"}
                      </span>
                    </td>
                    <td>{order.total_executed}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

export default OrdersPage;
