import React, { useState, useEffect } from "react";
import "../style/BuySellPage.css"; // Adjust the path as per your project structure
import { useParams } from "react-router-dom";
import { buyOrder, sellOrder, getbalance } from "../api"; // Adjust the path according to your file structure
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const BuySellPage = () => {
  const [volume, setBuyAmount] = useState("");
  const [price, setPrice] = useState(""); // Unified price state
  const [sellAmount, setSellAmount] = useState("");
  const [sellPrice, setSellPrice] = useState("");
  const [buyBalance, setBuyBalance] = useState(null); // Balance for the buy form
  const [sellBalance, setSellBalance] = useState(null); // Balance for the sell form
  const { symbol } = useParams();
  const [baseCurrency, quoteCurrency] = symbol ? symbol.split("-") : ["", ""];

  // Fetch balance for the symbol
  useEffect(() => {
    const fetchBalance = async () => {
      try {
        const response = await getbalance(symbol);
        if (response && response.data) {
          const buyWallet = response.data.find(wallet => wallet.wallet_type === quoteCurrency); // Match quoteCurrency for buy balance
          const sellWallet = response.data.find(wallet => wallet.wallet_type === baseCurrency); // Match baseCurrency for sell balance
          setBuyBalance(buyWallet);
          setSellBalance(sellWallet);

          // Set the price (if available) from the getlatestprice response
          const latestPrice = response.getlatestprice ? response.getlatestprice[0] : null;
          if (latestPrice) {
            setPrice(latestPrice.raw_price); // Set the price to the input box
          }
        }
      } catch (error) {
        // console.error("Error fetching balance:", error);
        // toast.error("Failed to fetch balance.");
      }
    };

    fetchBalance();
  }, [symbol, baseCurrency, quoteCurrency]);

  // Handle Buy form submission
  const handleBuySubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await buyOrder(symbol, price, volume);
      console.log("Buy Order Response:", response);
      toast.success(`${symbol} Buy order placed successfully!`);
    } catch (error) {
      console.error("Error placing buy order:", error);
      toast.error("Failed to place buy order.");
    }
  };

  // Handle Sell form submission
  const handleSellSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await sellOrder(symbol, sellPrice, sellAmount);
      toast.success(`${symbol} Sell order placed successfully!`);
    } catch (error) {
      console.error("Error placing sell order:", error);
      toast.error("Failed to place sell order.");
    }
  };

  return (
    <div className="buy-sell-page">
      {/* Display balance info for Buy Order */}
      <p className="balance-info">
        Buy Orders
        <span className="balance-gap"> | </span>
        Bal {quoteCurrency}:{" "}
        {buyBalance ? buyBalance.balance.toFixed(4) : "0"}
      </p>
      <form onSubmit={handleBuySubmit}>
        <div className="form-group">
          <div className="input-container">
            <div className="text-part">
              <span className="price-text">Enter amount</span>
            </div>
            <input
              id="buyAmount"
              className="user-input"
              type="number"
              value={volume}
              onChange={(e) => setBuyAmount(e.target.value)}
              placeholder=""
              required
            />
          </div>
        </div>
        <div className="form-group">
          <div className="input-container">
            <div className="text-part">
              <span className="price-text">Enter price</span>
            </div>
            <input
              id="buyPrice"
              className="user-input"
              type="number"
              value={price}
              onChange={(e) => setPrice(e.target.value)}
              placeholder=""
              required
            />
          </div>
        </div>
        <div className="form-group">
          <input
            id="buyTotal"
            className="form-control"
            type="number"
            value={price * volume || ""}
            placeholder="Total amount"
            readOnly
          />
        </div>

        <button type="submit" className="btn btn-primary">
          Place Buy Order
        </button>
      </form>

      <hr />

      {/* Display balance info for Sell Order */}
      <p className="balance-info">
        Sell Orders
        <span className="balance-gap"> | </span>
        Bal {baseCurrency}:{" "}
        {sellBalance ? sellBalance.balance.toFixed(4) : "0"}
      </p>

      {/* Sell Order Form */}
      <form onSubmit={handleSellSubmit}>
        <div className="form-group">
          <div className="input-container">
            <div className="text-part">
              <span className="price-text">Enter amount</span>
            </div>
            <input
              id="sellAmount"
              className="user-input"
              type="number"
              value={sellAmount}
              onChange={(e) => setSellAmount(e.target.value)}
              placeholder=""
              required
            />
          </div>
        </div>
        <div className="form-group">
          <div className="input-container">
            <div className="text-part">
              <span className="price-text">Enter price</span>
            </div>
            <input
              id="sellPrice"
              className="user-input"
              type="number"
              value={sellPrice}
              onChange={(e) => setSellPrice(e.target.value)}
              placeholder=""
              required
            />
          </div>
        </div>
        <div className="form-group">
          <input
            id="sellTotal"
            className="form-control"
            type="number"
            value={sellAmount * sellPrice || ""}
            placeholder="Total amount"
            readOnly
          />
        </div>
        <button type="submit" className="sell-button">
          Place Sell Order
        </button>
      </form>
    </div>
  );
};

export default BuySellPage;
