import React, { useState, useEffect } from "react";
import { gettransaction } from "../api";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../style/WalletPage.css";
import { useNavigate } from "react-router-dom"; // Import useNavigate hook

const INRDeposithistory = () => {
  const [walletData, setWalletData] = useState([]);
  const [Totalbalance, setTotalbalance] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [activeWallet, setActiveWallet] = useState(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const navigate = useNavigate(); // Initialize navigate function

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  useEffect(() => {
    const fetchWallet = async () => {
      try {
        const response = await gettransaction();
        setWalletData(response.data.inr_history);
      } catch (err) {
        setLoading(false);
        setError("Error fetching wallet data.");
        toast.error("Error fetching wallet data.");
      }
    };

    fetchWallet();
  }, []);

  return (
    <div className="wallet-container">
      <div className="balance-card">
        <div className="balance-card-header">
          <button className="sidebar-button" onClick={toggleSidebar}>
            Sidebar
          </button>
          <p className="balance-amount" id="totalBalance">
            Deposit History
          </p>
        </div>

        {isSidebarOpen && (
          <div className="sidebar">
            <button
              className="sidebar-item"
              onClick={() => navigate("/Deposithistory")}
            >
              Deposit History
            </button>{" "}
            <button
              className="sidebar-item"
              onClick={() => navigate("/INRDeposithistory")}
            >
             INR Deposit History
            </button>
            <button
              className="sidebar-item"
              onClick={() => navigate("/WithdrawalHistory")}
            >
              Withdrawal History
            </button>{" "}
            <button
              className="sidebar-item"
              onClick={() => navigate("/OpenOrder")}
            >
              Open Order
            </button>{" "}
            <button
              className="sidebar-item"
              onClick={() => navigate("/CloseOrder")}
            >
              Close Order
            </button>{" "}
          </div>
        )}
      </div>
      {error && <p className="error-message">{error}</p>}

      {walletData.length > 0 ? (
                <div className="table-container">

        <table className="wallet-table">
          <thead>
            <tr>
              <th></th>
              <th>symbol</th>
              <th>Balance</th>
              <th>Status</th>
          
              <th>UTR</th>
              <th>Txr Id</th>

              <th>Date</th>
            </tr>
          </thead>
          <tbody>
            {walletData.map((wallet) => (
              <React.Fragment key={wallet._id}>
                <tr>
                  <td>
                    <img
                      src={wallet.icon}
                      alt={wallet.symbol}
                      className="currency-icon"
                    />
                  </td>
                  <td>{wallet.symbol}</td>
                  <td>{parseFloat(wallet.amount).toFixed(6)}</td>
                  <td>{wallet.status === 0 ? 'Pending' : 'Success'}</td>
                  <td
                  >
                    {wallet.utr
                      ? `${wallet.utr}`
                      : "N/A"}
                  </td>
                  <td
                  >
                    {wallet.transection_id
                      ? `${wallet.transection_id}`
                      : "N/A"}
                  </td>
                  <td>{new Date(wallet.createdAt).toLocaleString()}</td>
                </tr>
              </React.Fragment>
            ))}
          </tbody>
        </table>
        </div>
      ) : (
        <p>No wallet data available.</p>
      )}
    </div>
    
  );
};

export default INRDeposithistory;
