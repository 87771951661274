import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from '../img/ctskola-0.png';
import '../style/Header.css';
import { AuthContext } from './AuthContext'; // Import AuthContext

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate();
  const { isLoggedIn, setIsLoggedIn } = useContext(AuthContext); // Access and update login state from AuthContext

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleNavigation = (path) => {
    navigate(path);
    setIsMenuOpen(false);
  };

  const handleLogout = () => {
    localStorage.removeItem('authToken'); // Remove auth token
    setIsLoggedIn(false); // Update login state in AuthContext
    navigate('/'); // Redirect to home page
  };

  return (
    <header className="header">
      <div className="header-container">
        <img src={logo} alt="Logo" className="logo" onClick={() => handleNavigation('/')} />
        <button className="menu-button" onClick={toggleMenu}>
          ☰
        </button>
        <nav className={`nav ${isMenuOpen ? 'nav-open' : ''}`}>
          <button className="nav-button" onClick={() => handleNavigation('/')}>
            HOME
          </button>
          <button className="nav-button" onClick={() => handleNavigation('/spot/RUBY-INR')}>
            EXCHANGE
          </button>

          {isLoggedIn ? (
            <>
              <button className="nav-button" onClick={() => handleNavigation('/wallet')}>
                WALLET
              </button>
              <button className="nav-button" onClick={() => handleNavigation('/profile')}>
                PROFILE
              </button>
              <button className="nav-button" onClick={handleLogout}>
                LOGOUT
              </button>
            </>
          ) : (
            <>
              <button className="nav-button" onClick={() => handleNavigation('/login')}>
                LOGIN
              </button>
              <button className="nav-button" onClick={() => handleNavigation('/register')}>
                REGISTER
              </button>
            </>
          )}
        </nav>
      </div>
    </header>
  );
};

export default Header;
