import React from "react";
import TradingPairDetails from "./TradingPairDetails";
import OrderBook from "./OrderBook";
import TradeHistory from "./TradeHistory";
import Chart from "./Chart";
import BuySellPage  from "./BuySellPage";
import OrdersPage  from "./OrdersPage";

import "../style/TradePage.css"; // Add custom styling if needed

const TradePage = () => {
  return (
    <div className="trade-page">
      <TradingPairDetails />

      <div className="trade-content">
        <div className="chart-and-orderbook">
          <div className="chart-container">
            <Chart />
          </div>
          <div className="orderbook-container">
            <OrderBook />
          </div>
          <div className="trade-history-container">
          <TradeHistory />
        </div>
        </div>  
        <div className="BuySellPage-and-order">
        <BuySellPage />
        <OrdersPage />
        </div> 
      </div>
    </div>
  );
};

export default TradePage;
