import React, { useState, useEffect } from "react";
import "../style/ProfilePage.css";
import {
  setPersonalInfo,
  verifyPanCard,
  sendAadharOtp,
  verifyAadharCard,
  getInfo,
} from "../api"; // Adjust the path according to your file structure
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const ProfilePage = () => {
  const [selectedSection, setSelectedSection] = useState("userInfo");
  const [clientId, setClientId] = useState("");

  const [kycInfo, setKycInfo] = useState({
    kyc_type: "1",
    first_name: "",
    date_of_birth: "",
    address: "",
    country: "",
    state: "",
    city: "",
    pincode: "",
    email: "",
    mobile_no: "",
    pan_card: "",
    aadhar_card: "",
  });

  const [passwords, setPasswords] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const [panVerified, setPanVerified] = useState(false);
  const [aadharVerified, setAadharVerified] = useState(false);
  const [otpSent, setOtpSent] = useState(false);
  const [otp, setOtp] = useState(""); // State for storing OTP input

  const handleKycSubmit = async (e) => {
    e.preventDefault();

    try {
      // Await the submission of KYC information
      await setPersonalInfo(kycInfo);
      toast.success("KYC information submitted successfully!");
      // Access the pan_card directly from kycInfo (since it's an object)
      const pan_cardNo = kycInfo.pan_card;
      // Await PAN card verification
      const response = await verifyPanCard(pan_cardNo);
      if (response.status === 200 && !response.error) {
        toast.success("PAN card verified successfully!");
        setPanVerified(true);
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      toast.error("Error submitting KYC information");
      console.error(error);
    }
  };

  const handleAadharVerification = (e) => {
    e.preventDefault();

    if (kycInfo.aadhar_card) {
      sendAadharOtp(kycInfo.aadhar_card)
        .then((response) => {
          if (response.status === 200 && !response.error) {
            toast.success("OTP sent successfully!");
            setOtpSent(true);
            // Store client_id for later use in OTP verification
            const client_id = response.result.data.client_id;
            setClientId(client_id); // Set client_id in state
          } else {
            toast.error(response.result.data.status);
          }
        })
        .catch((error) => {
          toast.error("Error sending OTP for Aadhaar verification.");
        });
    } else {
      toast.error("Please enter Aadhaar card number.");
    }
  };

  const handleAadharOtpSubmit = (e) => {
    e.preventDefault();

    if (otp && kycInfo.aadhar_card && clientId) {
      // Use the response from the previous OTP sent request to get client_id
      verifyAadharCard(kycInfo.aadhar_card, otp, clientId) // Passing the aadharno, otp, and client_id
        .then((response) => {
          if (response.status === 200 && !response.error) {
            toast.success("Aadhaar OTP verified successfully!");

            setAadharVerified(true);
          } else {
            toast.error("Error verifying Aadhaar OTP.");
          }
        })
        .catch((error) => {
          toast.error("Error verifying Aadhaar OTP.");

          console.error(error);
        });
    } else {
      toast.error("Please enter the OTP.");
    }
  };

  const handlePasswordChange = (e) => {
    e.preventDefault();
    if (passwords.newPassword !== passwords.confirmPassword) {
      toast.error("New password and confirm password do not match!");

      return;
    }
    toast.success("Password changed successfully!");
  };

  const [userInfo, setUserInfo] = useState(null); // Set initial state to null
  const [checkkyc, setcheckkyc] = useState(0); // Set initial state to null

  // Fetch user info when component mounts
  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const response = await getInfo(); // Your API call here

        // Ensure response contains data and set the user info
        if (response && response.response) {
          setUserInfo(response.response); // Store the response data in state
          setcheckkyc(response.checkkyc);
        } else {
          console.error("Error: User info not found in response.");
        }
      } catch (err) {
        console.error("Error fetching user info", err);
      }
    };

    fetchUserInfo();
  }, []); // Empty dependency array ensures it runs only once on component mount

  // If userInfo is null, return a loading state

  return (
    <div className="profile-page">
      <div className="sidebar">
        <button
          onClick={() => setSelectedSection("userInfo")}
          className={selectedSection === "userInfo" ? "active" : ""}
        >
          User Info
        </button>
        <button
          onClick={() => setSelectedSection("kyc")}
          className={selectedSection === "kyc" ? "active" : ""}
        >
          KYC
        </button>
        <button
          onClick={() => setSelectedSection("passwordChange")}
          className={selectedSection === "passwordChange" ? "active" : ""}
        >
          Change Password
        </button>
      </div>

      <div className="content">
        {selectedSection === "userInfo" && (
          <section className="user-info-section">
            <h2>User Information</h2>

            {checkkyc === 0 ? (
              // If checkkyc is 0, show the KYC verification message
              <p>Please verify your KYC.</p>
            ) : (
              // If checkkyc is 1, show the user information form
              <form>
                <div>
                  <label>Name:</label>
                  <input type="text" value={userInfo.first_name} readOnly />
                </div>
                <div>
                  <label>Email:</label>
                  <input type="email" value={userInfo.email} readOnly />
                </div>
                <div>
                  <label>Mobile Number:</label>
                  <input type="text" value={userInfo.mobile_no} readOnly />
                </div>
                <div>
                  <label>Date of Birth:</label>
                  <input type="text" value={userInfo.date_of_birth} readOnly />
                </div>
                <div>
                  <label>Address:</label>
                  <input type="text" value={userInfo.address} readOnly />
                </div>
                <div>
                  <label>State:</label>
                  <input type="text" value={userInfo.state} readOnly />
                </div>
                <div>
                  <label>City:</label>
                  <input type="text" value={userInfo.city} readOnly />
                </div>
                <div>
                  <label>Country:</label>
                  <input type="text" value={userInfo.country} readOnly />
                </div>
                <div>
                  <label>PAN Card:</label>
                  <input type="text" value={userInfo.doc_2_no} readOnly />
                </div>
                <div>
                  <label>Aadhaar:</label>
                  <input type="text" value={userInfo.doc_1_no} readOnly />
                </div>
                <div>
                  <label>Key Date:</label>
                  <input type="text" value={userInfo.createdAt} readOnly />
                </div>
              </form>
            )}
          </section>
        )}

        {selectedSection === "kyc" && (
          <section className="kyc-section">
            <h2>KYC Information</h2>
            {checkkyc === 1 ? (
              // If checkkyc is 0, show the KYC verification message
              <p> Your KYC already verify.</p>
            ) : (
            <form onSubmit={handleKycSubmit}>
              <div>
                <label>First Name:</label>
                <input
                  type="text"
                  value={kycInfo.first_name}
                  onChange={(e) =>
                    setKycInfo({ ...kycInfo, first_name: e.target.value })
                  }
                />
              </div>
              <div>
                <label>Date of Birth:</label>
                <input
                  type="date"
                  value={kycInfo.date_of_birth}
                  onChange={(e) =>
                    setKycInfo({ ...kycInfo, date_of_birth: e.target.value })
                  }
                />
              </div>
              <div>
                <label>Address:</label>
                <input
                  type="text"
                  value={kycInfo.address}
                  onChange={(e) =>
                    setKycInfo({ ...kycInfo, address: e.target.value })
                  }
                />
              </div>
              <div>
                <label>Country:</label>
                <input
                  type="text"
                  value={kycInfo.country}
                  onChange={(e) =>
                    setKycInfo({ ...kycInfo, country: e.target.value })
                  }
                />
              </div>
              <div>
                <label>State:</label>
                <input
                  type="text"
                  value={kycInfo.state}
                  onChange={(e) =>
                    setKycInfo({ ...kycInfo, state: e.target.value })
                  }
                />
              </div>
              <div>
                <label>City:</label>
                <input
                  type="text"
                  value={kycInfo.city}
                  onChange={(e) =>
                    setKycInfo({ ...kycInfo, city: e.target.value })
                  }
                />
              </div>
              <div>
                <label>Pincode:</label>
                <input
                  type="text"
                  value={kycInfo.pincode}
                  onChange={(e) =>
                    setKycInfo({ ...kycInfo, pincode: e.target.value })
                  }
                />
              </div>
              <div>
                <label>Email:</label>
                <input
                  type="email"
                  value={kycInfo.email}
                  onChange={(e) =>
                    setKycInfo({ ...kycInfo, email: e.target.value })
                  }
                />
              </div>
              <div>
                <label>Mobile Number:</label>
                <input
                  type="text"
                  value={kycInfo.mobile_no}
                  onChange={(e) =>
                    setKycInfo({ ...kycInfo, mobile_no: e.target.value })
                  }
                />
              </div>
              <div>
                <label>Pan Card Number:</label>
                <input
                  type="text"
                  value={kycInfo.pan_card}
                  onChange={(e) =>
                    setKycInfo({ ...kycInfo, pan_card: e.target.value })
                  }
                />
              </div>
              <button type="submit">Submit KYC</button>
            </form>
 )}
            {panVerified && !aadharVerified && (
              <div className="aadhar-verification">
                <h3>Aadhaar Verification</h3>
                <form onSubmit={handleAadharVerification}>
                  <div>
                    <label>Aadhar Card Number:</label>
                    <input
                      type="text"
                      value={kycInfo.aadhar_card}
                      onChange={(e) =>
                        setKycInfo({ ...kycInfo, aadhar_card: e.target.value })
                      }
                    />
                  </div>
                  <button type="submit">Send OTP</button>
                </form>

                {/* OTP input after OTP is sent */}
                {otpSent && (
                  <form onSubmit={handleAadharOtpSubmit}>
                    <div>
                      <label>Enter OTP:</label>
                      <input
                        type="text"
                        value={otp}
                        onChange={(e) => setOtp(e.target.value)}
                      />
                    </div>
                    <button type="submit">Verify OTP</button>
                  </form>
                )}

                {aadharVerified && <p>Aadhaar OTP verified successfully!</p>}
              </div>
            )}
         
          </section>
          
        )}

        {selectedSection === "passwordChange" && (
          <section className="password-change-section">
            <h2>Change Password</h2>
            <form onSubmit={handlePasswordChange}>
              <div>
                <label>Current Password:</label>
                <input
                  type="password"
                  value={passwords.currentPassword}
                  onChange={(e) =>
                    setPasswords({
                      ...passwords,
                      currentPassword: e.target.value,
                    })
                  }
                />
              </div>
              <div>
                <label>New Password:</label>
                <input
                  type="password"
                  value={passwords.newPassword}
                  onChange={(e) =>
                    setPasswords({ ...passwords, newPassword: e.target.value })
                  }
                />
              </div>
              <div>
                <label>Confirm New Password:</label>
                <input
                  type="password"
                  value={passwords.confirmPassword}
                  onChange={(e) =>
                    setPasswords({
                      ...passwords,
                      confirmPassword: e.target.value,
                    })
                  }
                />
              </div>
              <button type="submit">Change Password</button>
            </form>
          </section>
        )}
      </div>
    </div>
  );
};

export default ProfilePage;
