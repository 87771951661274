import React, { useState, useEffect, useRef } from "react";
import "../style/OrderBook.css"; // Add styles for the order book
import {
  subscribeToOrderBook,
  unsubscribeFromOrderBook,
} from "../socketService";
import { useParams } from "react-router-dom";
import { getprice } from "../api"; // Adjust the path according to your file structure

const OrderBook = () => {
  const { symbol } = useParams();
  const [price, setPrice] = useState(""); // Unified price state
  const [orderBookData, setOrderBookData] = useState({ asks: {}, bids: {} });
  const askOrdersRef = useRef(null);

  useEffect(() => {
    const handleOrderBookUpdate = (data) => {
      if (data && data.asks && data.bids) {
        setOrderBookData(data);
      } else if (data.updatedOrderBook) {
        setOrderBookData(data.updatedOrderBook);
      } else {
        setOrderBookData({ asks: {}, bids: {} });
      }
    };

    subscribeToOrderBook(symbol, handleOrderBookUpdate);

    return () => {
      unsubscribeFromOrderBook(symbol);
    };
  }, [symbol]);

  useEffect(() => {
    if (askOrdersRef.current) {
      askOrdersRef.current.scrollTop = askOrdersRef.current.scrollHeight;
    }
  }, [orderBookData]);

  // Sorting the asks and bids
  const asksArray = Object.entries(orderBookData.asks || {})
    .map(([price, quantity]) => ({ price: parseFloat(price), quantity }))
    .sort((b, a) => a.price - b.price);

  const bidsArray = Object.entries(orderBookData.bids || {})
    .map(([price, quantity]) => ({ price: parseFloat(price), quantity }))
    .sort((a, b) => b.price - a.price);



    useEffect(() => {
        const fetchBalance = async () => {
          try {
            const response = await getprice(symbol);

            if (response && response) {
              // Set the price (if available) from the getlatestprice response
              const latestPrice = response.getlatestprice ? response.getlatestprice[0] : null;
              if (latestPrice) {
                setPrice(latestPrice.raw_price); // Set the price to the input box
              }
            }
          } catch (error) {
            console.error("Error fetching balance:", error);
          }
        };
    
        fetchBalance();
      }, [symbol]);
  return (
    <div className="order-book">
      <h3>Order Book</h3>
      <div className="orders">
        <div className="price-valume">
          <p>Price</p>
          <p>Volume</p>
        </div>

        <div className="asks" ref={askOrdersRef}>
          {/* <h8>Sell Orders</h8> */}

          {asksArray.map((ask, index) => (
            <div key={index} className="order ask">
              <span>{ask.price}</span>
              <span>{ask.quantity}</span>
            </div>
          ))}
        </div>
        <h4>{price}</h4>
        <div class="line-break"></div>
        <div className="bids">
          {/* <h8>Buy Orders</h8> */}
          {bidsArray.map((bid, index) => (
            <div key={index} className="order bid">
              <span>{bid.price}</span>
              <span>{bid.quantity}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default OrderBook;
