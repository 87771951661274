import React, { useEffect, useState, useRef } from 'react';
import { createChart } from 'lightweight-charts';
import { subscribeToTradeHistory, unsubscribeFromOrderBook } from "../socketService";
import { useParams } from "react-router-dom";

const ChartComponent = () => {
  const { symbol } = useParams();
  const [tradeHistory, setTradeHistory] = useState([]);
  const [filteredData, setFilteredData] = useState([]); // Data filtered by timeframe
  const [timeframe, setTimeframe] = useState('all'); // Default to 'all'
  const chartContainerRef = useRef(null); // Ref for the chart container

  // Timeframe options
  const timeframes = {
    '1m': 60,      // 1 minute in seconds
    '1h': 3600,    // 1 hour in seconds
    '1d': 86400,   // 1 day in seconds
    '7d': 604800,  // 7 days in seconds
    all: Infinity, // All data
  };

  // Handle trade history updates
  useEffect(() => {
    const handleTradeHistoryUpdate = (data) => {
      if (data && data.symbol === symbol && data.tradeHistory) {
        const formattedData = data.tradeHistory.map((trade, index, array) => {
          const open = index === 0 ? parseFloat(array[array.length - 1]?.price || 0) : parseFloat(array[index - 1]?.price || 0);
          const close = parseFloat(trade.price);
          const high = Math.max(...array.slice(0, index + 1).map((t) => parseFloat(t.price)));
          const low = Math.min(...array.slice(0, index + 1).map((t) => parseFloat(t.price)));
    
          return {
            time: new Date(parseInt(trade.trade_date)).getTime() / 1000, // Convert to seconds
            open,
            high,
            low,
            close,
          };
        });
    
        // Sort formattedData by time in ascending order
        formattedData.sort((a, b) => a.time - b.time);
    
        setTradeHistory(formattedData);
      } else {
        setTradeHistory([]); // Reset if no valid data
      }
    };
    

    subscribeToTradeHistory(symbol, handleTradeHistoryUpdate);

    return () => {
      unsubscribeFromOrderBook(symbol);
    };
  }, [symbol]);

  // Filter trade history based on the selected timeframe
  useEffect(() => {
    if (timeframe === 'all') {
      setFilteredData(tradeHistory);
    } else {
      const now = Math.floor(Date.now() / 1000); // Current timestamp in seconds
      const timeframeInSeconds = timeframes[timeframe];
      const filtered = tradeHistory.filter((data) => now - data.time <= timeframeInSeconds);
      setFilteredData(filtered);
    }
  }, [timeframe, tradeHistory]);

  // Initialize chart
  useEffect(() => {
    if (!chartContainerRef.current || !filteredData.length) return;

    const chartProperties = {
      width: chartContainerRef.current?.clientWidth || 1500,
      height: chartContainerRef.current?.clientHeight || 600,
      timeScale: {
        timeVisible: true,
        secondsVisible: true,
      },
    };

    const chart = createChart(chartContainerRef.current, chartProperties);
    const candleseries = chart.addCandlestickSeries();

    candleseries.setData(filteredData);

    return () => {
      chart.remove();
    };
  }, [filteredData]);

  return (
    <div style={{ position: 'relative', width: '100%', height: '400px' }}>
      {/* Timeframe Selection Buttons */}
      <div style={{ marginBottom: '10px', display: 'flex', gap: '10px' }}>
        {Object.keys(timeframes).map((key) => (
          <button
            key={key}
            onClick={() => setTimeframe(key)}
            style={{
              padding: '5px 10px',
              background: timeframe === key ? '#007bff' : '#f0f0f0',
              color: timeframe === key ? '#fff' : '#000',
              border: '1px solid #ccc',
              cursor: 'pointer',
            }}
          >
            {key.toUpperCase()}
          </button>
        ))}
      </div>

      {/* Chart Container */}
      <div ref={chartContainerRef} style={{ width: '100%', height: '400px' }} />
    </div>
  );
};

export default ChartComponent;
