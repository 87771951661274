import React from 'react';
import '../style/Footer.css'; // Adjust the path as per your project structure

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <p className="footer-text">
          © {new Date().getFullYear()} CTSKOLA. All rights reserved.
        </p>
        <ul className="footer-links">
          <li><a href="/about-us">About Us</a></li>
          <li><a href="/terms">Terms of Service</a></li>
          <li><a href="/privacy">Privacy Policy</a></li>
          <li><a href="/contact">Contact Us</a></li>
        </ul>
      </div>
    </footer>
  );
};

export default Footer;
