import React, { useState, useEffect } from "react";
import { getuserorder } from "../api";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../style/WalletPage.css";
import { useNavigate } from "react-router-dom"; // Import useNavigate hook

const WithdrawalHistory = () => {
  const [orders, setorders] = useState([]);
  const [error, setError] = useState("");
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const navigate = useNavigate(); // Initialize navigate function

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  useEffect(() => {
    const fetchWallet = async () => {
      try {
        const response = await getuserorder();
        setorders(response.data);
      } catch (err) {
        setError("Error fetching wallet data.");
        toast.error("Error fetching wallet data.");
      }
    };

    fetchWallet();
  }, []);
  const closedOrders = orders.filter(
    (order) => order.order_status === 1 || order.order_status === 2
  );
  return (
    <div className="wallet-container">
      <div className="balance-card">
        <div className="balance-card-header">
          <button className="sidebar-button" onClick={toggleSidebar}>
            Sidebar
          </button>
          <p className="balance-amount" id="totalBalance">
          Close Orders  
          </p>
        </div>

        {isSidebarOpen && (
          <div className="sidebar">
            <button
              className="sidebar-item"
              onClick={() => navigate("/Deposithistory")}
            >
              Deposit History
            </button>{" "}
            <button
              className="sidebar-item"
              onClick={() => navigate("/INRDeposithistory")}
            >
             INR Deposit History
            </button>
            <button
              className="sidebar-item"
              onClick={() => navigate("/WithdrawalHistory")}
            >
              Withdrawal History
            </button>{" "}
            <button
              className="sidebar-item"
              onClick={() => navigate("/OpenOrder")}
            >
              Open Order
            </button>{" "}
            <button
              className="sidebar-item"
              onClick={() => navigate("/CloseOrder")}
            >
              Close Order
            </button>{" "}
          </div>
        )}
      </div>
      {error && <p className="error-message">{error}</p>}

      {closedOrders.length > 0 ? (
                <div className="table-container">

        <table className="wallet-table">
          <thead>
            <tr>
              <th>symbol</th>
              <th>orderType</th>
              <th>price</th>
              <th>volume</th>
              <th>Status</th>
              <th>Date</th>
            </tr>
          </thead>
          <tbody>
            {closedOrders.map((wallet) => (
              <React.Fragment key={wallet._id}>
                <tr>
                  <td>{wallet.symbol}</td>
                  <td>{wallet.orderType}</td>
                  <td>{wallet.price.toFixed(6)}</td>
                  <td>
                    {parseFloat(wallet.volume + wallet.total_executed).toFixed(
                      6
                    )}
                  </td>
                  <td>
                    {wallet.order_status === 1
                      ? "Closed"
                      : wallet.order_status === 2
                      ? "Cancelled"
                      : wallet.order_status}
                  </td>
                  <td>{new Date(wallet.createdAt).toLocaleString()}</td>
                </tr>
              </React.Fragment>
            ))}
          </tbody>
        </table>
        </div>
      ) : (
        <p>No wallet data available.</p>
      )}
    </div>
  );
};

export default WithdrawalHistory;
