import React, { useState, useEffect } from "react";
import "../style/TradingPairDetails.css";
import { fetchTradingPairs } from "../api";
import { useParams, useNavigate } from "react-router-dom";
import { subscribeToTradeHistory, unsubscribeFromOrderBook } from "../socketService";

const TradingPairDetails = () => {
  const { symbol } = useParams();
  const navigate = useNavigate();
  const [pairs, setPairs] = useState([]); // Store trading pairs
  const [selectedCurrency, setSelectedCurrency] = useState("INR"); // Default currency
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [last24data, setLast24Data] = useState([]);
  const [lowPrice, setLowPrice] = useState(null);
  const [highPrice, setHighPrice] = useState(null);
  const [lastPrice, setLastPrice] = useState(null);
  const [totalVolume, settotalVolume] = useState(null);

  const GetPairAllCoinsInfo = async () => {
    setIsLoading(true);
    try {
      const response = await fetchTradingPairs();
      if (response) {
        setPairs(response || []);
      }
    } catch (error) {
      console.error("Error fetching trading pairs:", error);
    } finally {
      setIsLoading(false);
    }
  };

  // Handle dropdown visibility
  const toggleDropdown = () => {
    if (!dropdownVisible) {
      GetPairAllCoinsInfo();
    }
    setDropdownVisible(!dropdownVisible);
  };

  // Handle pair selection to navigate to a specific trading pair
  const handlePairClick = (pair) => {
    setDropdownVisible(false);
    navigate(`/spot/${pair}`);
  };

  // Handle currency selection
  const handleCurrencyClick = (currency) => {
    setSelectedCurrency(currency);
  };


  useEffect(() => {
    const handleTradeHistoryUpdate = (data) => {
      if (data && data.symbol === symbol && data.tradeHistory) {
        
        const now = Date.now();
        const last24Hours = 124 * 60 * 60 * 1000;
        // Filter trade history for the last 24 hours
        const filteredData = data.tradeHistory.filter((trade) => {
          const tradeTimestamp = parseInt(trade.trade_date, 10);
          return now - tradeTimestamp <= last24Hours;
        });

        setLast24Data(filteredData);

        // Calculate low, high, and last prices in the last 24 hours
        if (filteredData.length > 0) {

          const prices = filteredData.map((trade) => parseFloat(trade.price));
          const volumes = filteredData.map((trade) => parseFloat(trade.volume));
          const totalVolume = volumes.reduce((sum, volume) => sum + volume, 0);
          setLowPrice(Math.min(...prices));
          setHighPrice(Math.max(...prices));
          setLastPrice(prices[prices.length - 1]); // Last price is from the most recent trade
          settotalVolume(totalVolume)
        } else {
          setLowPrice(0);
          setHighPrice(0);
          setLastPrice(0);
        }
      } else {
        setLast24Data([]);
        setLowPrice(0);
        setHighPrice(0);
        setLastPrice(0);
      }
    };

    subscribeToTradeHistory(symbol, handleTradeHistoryUpdate);

    return () => {
      unsubscribeFromOrderBook(symbol);
    };
  }, [symbol]);

  return (
    <div className="trading-pair-details">
      <div className="pair-info" onClick={toggleDropdown}>
        Market {dropdownVisible ? "▲" : "▼"}
      </div>
      <div className="metrics">
        <div>
          pair: <strong>{symbol}</strong>
        </div>
        <div>
          Last Price: <strong>{lastPrice}</strong>
        </div>
        <div>
          Volume: <strong>{totalVolume}</strong>
        </div>
        <div>
          High: <strong>{highPrice}</strong>
        </div>
        <div>
          Low: <strong>{lowPrice}</strong>
        </div>
        <div>
          AVG: <strong>-3.03%</strong>
        </div>
      </div>

      {/* Dropdown for pairs */}
      {dropdownVisible && (
        <div className="dropdown">
          <div className="currency-card-container">
            {["RUBY", "INR", "USDT", "BTC"].map((currency) => (
              <div
                key={currency}
                className={`currency-card ${
                  selectedCurrency === currency ? "selected" : ""
                }`}
                onClick={() => handleCurrencyClick(currency)}
              >
                {currency}
              </div>
            ))}
          </div>
          {isLoading ? (
            <p>Loading...</p>
          ) : (
            (pairs[selectedCurrency] || []).map((pair, index) => (
              <div
                key={index}
                className="dropdown-item"
                onClick={() =>
                  handlePairClick(pair.symbol + `-${selectedCurrency}`)
                }
              >
                <img
                  src={pair.icon}
                  alt={pair.market}
                  style={{ width: "20px", marginRight: "10px" }}
                />
                <span className="pair-symbol">
                  {pair.symbol + `-${selectedCurrency}`}
                </span>
                <span className="pair-price">{pair.price}</span>
                <span className="dropdown-price" style={{ marginLeft: "10px" }}>
                  {pair[`${selectedCurrency}_price`]}{" "}
                </span>
              </div>
            ))
          )}
        </div>
      )}

      {/* Price Display Section */}
    </div>
  );
};

export default TradingPairDetails;
