import React, { useState } from 'react';
import '../style/Auth.css'; // Common CSS for both Login and Registration pages
import { registerUser, verifyUserEmail } from '../api'; // Adjust the path according to your file structure
import { toast, ToastContainer } from 'react-toastify'; // Import toast for notifications
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom'; // Import useNavigate hook

const Register = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false); // State to manage loading state
  const [showPopup, setShowPopup] = useState(false); // State to show OTP popup
  const [otp, setOtp] = useState(''); // State to manage OTP input
  const navigate = useNavigate(); // Initialize navigate function

  const handleRegister = async (e) => {
    e.preventDefault();
    setLoading(true); // Show loading state

    // Validation (simple check)
    if (!name || !email || !password) {
      toast.error('All fields are required!');
      setLoading(false);
      return;
    }

    try {
      let payload = {
        name: name,
        email: email,
        password: password,
      };
      // Register user by calling the API function
      const registerUsers = await registerUser(payload);

      // Assuming `registerUsers` contains a success message
      if (registerUsers && registerUsers.status==200) {
        toast.success('Registration successful! Please verify your email.');
        localStorage.setItem('authToken', registerUsers.params.token);

        // Show the OTP verification popup
        setShowPopup(true);
      } else {
        toast.error(registerUsers.message || 'Registration failed!');
      }
    } catch (err) {
      console.error('Registration error:', err);
      toast.error('An error occurred. Please try again later.');
    } finally {
      setLoading(false); // Reset loading state after the API call
    }
  };

  const handleVerifyOtp = async () => {
    if (!otp) {
      toast.error('Please enter the OTP!');
      return;
    }

    try {
      // Call the verifyUserEmail API with the OTP
      const verifyResponse = await verifyUserEmail(otp);
      if (verifyResponse && verifyResponse.error===false) {
        toast.success('Email verified successfully!');
        setShowPopup(false); // Close the popup after successful verification
        navigate('/'); // Navigate to the specified path

      } else {
        toast.error(verifyResponse.message || 'Verification failed!');
      }
    } catch (err) {
      console.error('Error verifying email:', err);
      toast.error('An error occurred while verifying the email. Please try again.');
    }
  };

  return (
    <div className="auth-container">
      <ToastContainer /> {/* Toast notifications container */}
      <h2>Register</h2>
      <form onSubmit={handleRegister}>
        <input
          type="text"
          placeholder="Full Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
        />
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <button type="submit" className="auth-button" disabled={loading}>
          {loading ? 'Registering...' : 'Register'}
        </button>
      </form>
      <p>
        Already have an account? <a href="/login">Login here</a>
      </p>

      {/* OTP Verification Popup */}
      {showPopup && (
        <div className="popup-overlay">
          <div className="popup-container">
            <h3>Verify Your Email</h3>
            <input
              type="text"
              placeholder="Enter OTP"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
              required
            />
            <button onClick={handleVerifyOtp} className="verify-button">
              Verify OTP
            </button>
            <button onClick={() => setShowPopup(false)} className="close-button">
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Register;
